<script setup lang="ts">
type Props = {
    refElement?: HTMLElement | null;
    offsetTop?: number;
    offsetLeft?: number;
    offsetRight?: number;
    maxHeight?: number | null;
    fitContent?: boolean;
    fullBorder?: boolean;
    isRightAligned?: boolean;
    hideBorder?: boolean;
    showOnTop?: boolean;
    dependPosOnScrollContainer?: boolean;
    setWidthOfRefElem?: boolean;
    centerToRefElem?: boolean;
    shadow?: boolean;
    rounded?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
    centerToRefElem: false,
    dependPosOnScrollContainer: false,
    fitContent: false,
    fullBorder: false,
    hideBorder: false,
    isRightAligned: false,
    maxHeight: null,
    offsetLeft: 0,
    offsetRight: 0,
    offsetTop: 0,
    refElement: null,
    rounded: false,
    setWidthOfRefElem: true,
    shadow: false,
    showOnTop: false,
});

const Y_BORDERS_WIDTH = 2;

const elementPos: Ref<Record<string, string>> = ref({});
const scrollContainer: Ref<HTMLElement | null> = ref(null);
const container: Ref<HTMLElement | null> = ref(null);
const maxHeightValue: Ref<number | null> = ref(props.maxHeight);
const maxWidthValue: Ref<number | null> = ref(null);
const isPosOnTop: Ref<boolean> = ref(false);
const isInvisible: Ref<boolean> = ref(false);

const emit = defineEmits(['outsideScrollOrResize', 'isOnTop', 'visible']);

const { getScrollContainer } = useUtilFunctions();

const checkPosition = (initElementPos?: Record<string, string>): void => {
    if (container.value && props.refElement && elementPos.value) {
        elementPos.value = initElementPos || {};
        const containerPos = container.value.getBoundingClientRect();
        const refElemPos = props.refElement.getBoundingClientRect();
        const scrollContainerPos =
            scrollContainer.value?.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;
        maxWidthValue.value = windowWidth;
        if (props.centerToRefElem) {
            const centerPositionLeft =
                refElemPos.left - (containerPos.width - refElemPos.width) / 2;
            if (centerPositionLeft > 0) {
                elementPos.value.left = `${centerPositionLeft}px`;
            }
        }
        let refElemInScrollContainer = 0;
        if (props.dependPosOnScrollContainer && scrollContainerPos) {
            if (refElemPos.top < scrollContainerPos.top) {
                refElemInScrollContainer = -1;
            } else if (refElemPos.bottom > scrollContainerPos.bottom) {
                refElemInScrollContainer = 1;
            }
        }
        if (maxHeightValue.value != null) {
            maxHeightValue.value += Y_BORDERS_WIDTH;
        }
        if (containerPos.width > windowWidth) {
            elementPos.value.left = '0px';
        } else if (
            parseInt(elementPos.value.left, 10) + containerPos.width >
                windowWidth ||
            props.isRightAligned
        ) {
            // check if there is space on the right side of the ref Element
            let leftVal = refElemPos.right - containerPos.width;
            if (leftVal < 0) {
                leftVal = 0;
            }
            elementPos.value.left = `${leftVal}px`;
        }
        container.value.style.left = elementPos.value.left;
        const tmpContainerTop =
            refElemPos.bottom + props.offsetTop + (props.fullBorder ? 2 : 0);
        container.value.style.top = `${tmpContainerTop}px`;
        containerPos.height = container.value.clientHeight;
        if (
            (tmpContainerTop + containerPos.height > windowHeight ||
                props.showOnTop) &&
            refElemInScrollContainer !== -1
        ) {
            // check if there is space above the ref Element
            if (refElemPos.top - containerPos.height - props.offsetTop > 0) {
                elementPos.value.top = `${
                    refElemPos.top -
                    containerPos.height -
                    props.offsetTop -
                    (props.fullBorder ? 2 : 0)
                }px`;
                isPosOnTop.value = true;
            } else {
                // there is no space above or below the element. Check on which side there is more
                // space and then put the container there with a minimized height
                const topSpace = refElemPos.top;
                const bottomSpace = windowHeight - refElemPos.bottom;
                if (topSpace > bottomSpace) {
                    maxHeightValue.value = topSpace - Y_BORDERS_WIDTH;
                    elementPos.value.top = `${
                        refElemPos.top - maxHeightValue.value
                    }px`;
                    isPosOnTop.value = true;
                } else {
                    maxHeightValue.value = bottomSpace - Y_BORDERS_WIDTH;
                    isPosOnTop.value = false;
                }
            }
        }
        container.value.style.top = elementPos.value.top;
    }
    isInvisible.value = false;
    emit('isOnTop', isPosOnTop.value);
    emit('visible', true);
};

const setElementPos = (): void => {
    if (props.refElement) {
        isInvisible.value = true;
        const clientRect = props.refElement.getBoundingClientRect();
        let { width, left } = clientRect;

        if (props.offsetLeft > 0) {
            left = clientRect.left + props.offsetLeft;
            width -= props.offsetLeft;
        }
        if (props.offsetRight > 0) {
            left -= props.offsetRight;
        }
        const tempElementPos = {
            left: `${left}px`,
            top: `${clientRect.bottom + props.offsetTop}px`,
            width: `${width}px`,
        };
        elementPos.value = {
            left: '0px',
            top: '0px',
        };
        if (container.value) {
            container.value.style.left = '0px';
            container.value.style.top = '0px';
            if (props.setWidthOfRefElem) {
                container.value.style.width = tempElementPos.width;
            }
        }
        setTimeout(() => {
            checkPosition(tempElementPos);
        }, 0);
    } else {
        elementPos.value = {};
    }
};

const onScrollOrResize = () => {
    emit('outsideScrollOrResize');
};

onMounted(() => {
    if (props.refElement) {
        scrollContainer.value = getScrollContainer(props.refElement);
        scrollContainer.value?.addEventListener('scroll', onScrollOrResize);
        window?.addEventListener('resize', onScrollOrResize);
    }
    setElementPos();
});

onUnmounted(() => {
    scrollContainer.value?.removeEventListener('scroll', onScrollOrResize);
    window?.removeEventListener('resize', onScrollOrResize);
});
</script>

<template>
    <div
        ref="container"
        class="fixed z-30 w-fit"
        tabindex="-1"
        :class="{
            'overflow-auto': maxHeightValue != null,
            'border-t-0': !isPosOnTop && !fullBorder,
            'rounded-b': !isPosOnTop,
            'rounded-t': isPosOnTop,
            'border border-gray-300 shadow': !hideBorder,
            invisible: isInvisible,
            'shadow-top': shadow,
            rounded: rounded,
        }"
        :style="{
            maxHeight: maxHeightValue ? maxHeightValue + 'px' : undefined,
            maxWidth: fitContent ? maxWidthValue + 'px' : undefined,
        }"
    >
        <slot />
    </div>
</template>
