<script setup inherit-attrs="false" lang="ts">
type Props = {
    placeholder?: string;
    isDisabled?: boolean;
    cursorPointer?: boolean;
    group?: string;
    modelValue?: string | null;
    errorMsg?: string | Ref<string> | null;
    isFocused?: boolean;
    isReadonly?: boolean;
    borderBottom?: boolean;
    gray?: boolean;
    hasError?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
    borderBottom: false,
    cursorPointer: false,
    errorMsg: null,
    gray: false,
    group: '',
    hasError: false,
    isDisabled: false,
    isFocused: false,
    isReadonly: false,
    modelValue: null,
    placeholder: '',
});

const inputElement: Ref<HTMLInputElement | null> = ref(null);

onMounted(() => {
    if (props.isFocused) {
        nextTick(() => {
            if (inputElement.value) {
                inputElement.value.focus();
            }
        });
    }
});

defineEmits(['onChange', 'onInput', 'onClick']);
</script>

<template>
    <input
        ref="inputElement"
        v-bind="$attrs"
        :data-testid="`${group} input`"
        class="w-full text-ellipsis px-4 text-base focus:outline-none"
        :class="{
            'cursor-pointer': cursorPointer,
            'border-red-700': !!errorMsg || hasError,
            'border-gray-300 focus-within:border-gray-900':
                !errorMsg && !hasError,
            'rounded-none border-b': borderBottom,
            'rounded border': !borderBottom,
            'bg-gray-50': gray,
        }"
        type="text"
        :placeholder="placeholder"
        :disabled="isDisabled"
        :readonly="isReadonly"
        :value="modelValue"
        @change="$emit('onChange', ($event.target as HTMLInputElement).value)"
        @input="$emit('onInput', ($event.target as HTMLInputElement).value)"
        @click="$emit('onClick')"
    />
    <div v-if="errorMsg">
        <BaseErrorMessage :message="errorMsg" />
    </div>
</template>
